/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onRouteUpdate = ({ location }) => {
  anchorScroll(location)
  return true
}

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  anchorScroll(location)
  return true
}

function anchorScroll(location) {
  if (location && location.hash) {
    setTimeout(() => {
      const loc = document.querySelector(`${decodeURI(location.hash)}`)
      if (loc) loc.scrollIntoView({ behavior: "smooth", block: "start" })
      else document.body.scrollIntoView({ behavior: "smooth", block: "start" })
    }, 0)
  } else {
    location &&
      setTimeout(() => {
        document.body.scrollIntoView()
      }, 0)
  }
}
